import React from 'react';
import { PlusOutlined, DeleteOutlined, StopOutlined } from '@ant-design/icons';
import styles from '../CreativeBuilder.module.css';
import { openWarningNotification } from '../../../../../../../../../utils/notifications';

export const ImageUploader = ({ images, setImages, setImageModalVisible }) => {
  const handleDeleteImage = (image) => {
    const filtered = images.filter((item) => item !== image);
    setImages(filtered);
  };
  return (
    <div>
      <p className={styles.builderLabel}>
        <span className={styles.requiredMark}>* </span>
        Image{' '}
        <span>
          Minimum size <span>600x400</span> pixels; max 5 images
        </span>
      </p>
      <div className={styles.previeImageBox}>
        {images.map((image, key) => {
          return (
            <div key={key} className={styles.singleImagePreview} onClick={() => handleDeleteImage(image)}>
              <img src={image} alt="creatives" />
              <div className={styles.singleImageDelete}>
                <DeleteOutlined className={styles.deleteIcon} />
              </div>
            </div>
          );
        })}
        <div
          className={styles.addNewImageBtn}
          onClick={() => {
            if (images.length < 5) {
              setImageModalVisible('bulk');
            } else {
              openWarningNotification({ message: "You can't add more than 5 images, please delete existing ones!" });
            }
          }}
        >
          {images.length < 5 ? <PlusOutlined style={{ fontSize: '2rem' }} /> : <StopOutlined style={{ fontSize: '2rem' }} />}
        </div>
      </div>
    </div>
  );
};
