import React from 'react';
import { Tooltip } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

export const AgreementBox = ({ setAgreementModalVisible, isAgreementRequested, docuSign }) => {

    const openModal = () => {
        setAgreementModalVisible(true);
    };

    const agreementTooltipContent =
        !docuSign ?
            <p style={{ margin: '0' }}>Please contact your Account Manager in order to sign the agreement. You must sign the agreement before your cumulative spent reaches $5,000. Otherwise, you won't be able to make payments.</p>
            :
            isAgreementRequested ?
                <p style={{ margin: '0' }}>Signing in progress. After both sides sign the agreement, you’ll receive an e-mail.</p>
                :
                <p style={{ margin: '0' }}>You must sign the agreement before your cumulative spent reaches $5,000. Otherwise, you won't be able to make payments.</p>;

    return (
        <Tooltip placement="bottom" title={agreementTooltipContent}>
            <div className="agreementContainer" onClick={!isAgreementRequested && docuSign ? openModal : undefined}>
                <div>
                    <p>Missing agreement!</p>
                    {
                        isAgreementRequested
                            ?
                            <p>Signing in progress.</p>
                            :
                            <p>{docuSign ? 'Click here to sign the agreement.' : 'Contact your Account Manager to sign the agreement.'}</p>
                    }
                </div>
                <ExclamationCircleOutlined />
            </div>
        </Tooltip>
    );
};
