import { callApi } from './api';

class ApiIntegrationService {
  async getCompanies() {
    return await callApi({
      url: `/api/companies/`
    });
  }
}

export const apiIntegrationService = new ApiIntegrationService();
