import React from 'react';
import { Tooltip } from 'antd';
import styles from './Show.module.css';

export const DateCreatedCol = ({ date }) => {
  return (
    <>
      <Tooltip title={date}>
        <div className={styles.dateCreated}>{date}</div>
      </Tooltip>
    </>
  );
};
