import React from 'react';
import styles from '../Payment.module.css';

import moment from 'moment';
import { Tag } from 'antd';

export const PaymentCanceled = ({ details }) => {
  return (
    <>
      <h3 className={styles.paymentTitle}>Your {details.payment_type} Payment Has Been Cancelled!</h3>
      <h4 className={styles.tyMessage}>Your transaction has been canceled.</h4>
      <div className={styles.transactionDetails}>
        <h4>Transaction Details</h4>
        <div className={styles.detailsPair}>
          <p>Company Name</p>
          <p>Coinis LTD</p>
        </div>
        <div className={styles.detailsPair}>
          <p>Shop ID</p>
          <p>COINISME</p>
        </div>
        <div className={styles.detailsPair}>
          <p>Shopping Cart ID</p>
          <p>{details.shopping_cart_id}</p>
        </div>
        <div className={styles.detailsPair}>
          <p>Date & Time</p>
          <p>{moment(details.date_created).format('Do MMM YYYY, h:mm a')}</p>
        </div>
        <div className={styles.detailsPair}>
          <p>Status</p>
          <Tag className={`${styles.tagStatus} ${styles.tagStatusCanceled}`}>
            Cancelled
          </Tag>
        </div>
      </div>
    </>
  );
};
