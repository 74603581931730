import { callApi } from './api';

const getQueryString = (queryParams, withoutQuestionMark = false) => {
  if (!queryParams) {
    return '';
  }

  const query = Object.keys(queryParams)
    .filter((key) => {
      const value = queryParams[key];
      return value !== undefined && value !== null && value !== '';
    })
    .map((key) => {
      const value = queryParams[key];
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    })
    .join('&');

  return query ? (withoutQuestionMark ? query : `?${query}`) : '';
};

export const getOffsetLimitFromPagination = ({ userNotificationPage, userNotificationPageSize }) => ({
  limit: userNotificationPageSize,
  offset: (userNotificationPage - 1) * userNotificationPageSize,
});

class UserNotificationsService {
  async getUserNotifications(userNotificationPage, userNotificationPageSize, showUnread) {
    const query = getQueryString({
      ...getOffsetLimitFromPagination({ userNotificationPage, userNotificationPageSize }),
      unread: showUnread && showUnread
    });
    return await callApi({
      url: `/api/notifications/advertiser_notifications/${query}`
    });
  }

  async readUserNotification(notificationId) {
    return await callApi({
      url: `/api/notifications/${notificationId}/mark_read/`,
      method: 'POST'
    });
  }

  async unreadUserNotification(notificationId) {
    return await callApi({
      url: `/api/notifications/${notificationId}/mark_unread/`,
      method: 'POST'
    });
  }
}

export const userNotificationsService = new UserNotificationsService();
