import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import UserContext from '../../../../contexts/userContext';
import { dashboardService } from '../../../../services/dashboard';
import { HeadCardInfo } from './HeadCardInfo';
import styles from './HeadInfo.module.css';
export const HeadInfo = () => {
  const [loading, setLoading] = useState(false);
  const { userInfo } = useContext(UserContext);
  const [cardData, setCardData] = useState({});
  useEffect(() => {
    const fetchCardInfo = async () => {
      setLoading(true);
      try {
        const response = await dashboardService.getTopCardInfo();
        setCardData(response.data);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };
    fetchCardInfo();
  }, []);
  return (
    <div className={styles.headInfoContainer}>
      <HeadCardInfo loading={loading} title="Active campaigns" value={cardData.active_cmp} valueDesc="" color="#73B666" />
      <HeadCardInfo loading={loading} title="Inactive campaigns" value={cardData.inactive_cmp} valueDesc="" color="#F9AF3B" />
      <HeadCardInfo loading={loading} title="Spendings" value={cardData.spendings} valueDesc="Last 7/30 Days" color="#438EF7" />
      <HeadCardInfo loading={loading} title="Current Balance" value={'$' + userInfo.total_balance} valueDesc="" color="#05296D" />
    </div>
  );
};
