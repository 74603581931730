import { Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { dashboardService } from '../../../services/dashboard';
import styles from '../Dashboard.module.css';

const columns = [
  {
    title: 'CAMPAIGN',
    dataIndex: 'campaign_name'
  },
  {
    title: 'CLICKS',
    dataIndex: 'clicks'
  },
  {
    title: 'IMPRESSIONS',
    dataIndex: 'impressions'
  },
  {
    title: 'CONVERSIONS',
    dataIndex: 'leads'
  },
  {
    title: 'SPENT',
    dataIndex: 'spent'
  }
];
const refactorTableData = (tableData) => {
  const tableDataRefactored = tableData
    ? tableData.map((el, index) => {
        const refactor = { ...el };
        refactor.spent = refactor.spent && '$ ' + refactor.spent.toFixed(2);
        refactor.cpm = refactor.cpm && '$ ' + refactor.cpm.toFixed(4);
        refactor.cr = refactor.cr && refactor.cr.toFixed(2) + ' %';
        return {
          key: index,
          ...refactor
        };
      })
    : '';
  return tableDataRefactored;
};

export const TopChannelsTable = ({ dateRange }) => {
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    const fetchTopChannels = async () => {
      setLoading(true);
      try {
        const response = await dashboardService.getTopChannelsData(dateRange);
        setTableData(response.table);
      } catch (e) {
      } finally {
        setLoading(false);
      }
    };
    fetchTopChannels();
  }, [dateRange]);
  return (
    <div className={styles.channelsTableContainer}>
      <h4>Top 10 Channels</h4>
      <Table
        loading={loading}
        columns={columns}
        dataSource={refactorTableData(tableData)}
        pagination={false}
        rowKey={({ campaign_name }) => campaign_name}
        scroll={{ x: window.innerWidth > 875 ? null : 875 }}
      />
    </div>
  );
};
