import React from 'react';
import { LeftCircleFilled, RightCircleFilled } from '@ant-design/icons';

export const ModuleName = ({ collapsed, onCollapsed }) => {
  return (
    <div
      className="sidebarModuleDisp"
      style={{
        backgroundImage: 'url(/images/rocket-sidebar.png)'
      }}
    >
      <span onClick={() => onCollapsed(!collapsed)}>
        {collapsed ? <RightCircleFilled style={{ fontSize: 34 }} /> : <LeftCircleFilled style={{ fontSize: 34 }} />}
      </span>
      <span>{collapsed ? '\xa0\xa0\xa0\xa0' : 'Advertiser'}</span>
    </div>
  );
};
