import React from 'react';
import styles from '../Payment.module.css';

import moment from 'moment';
import { Tag } from 'antd';

export const PaymentCompleted = ({ details }) => {
  return (
    <>
      <h3 className={styles.paymentTitle}>Your {details.payment_type} Payment Has Been Successful!</h3>
      <h4 className={styles.tyMessage}>Thank you for using this service.</h4>
      <div className={styles.transactionDetails}>
        <h4>Transaction Details</h4>
        <div className={styles.detailsPair}>
          <p>Company Name</p>
          <p>Coinis LTD</p>
        </div>
        <div className={styles.detailsPair}>
          <p>Shop ID</p>
          <p>COINISME</p>
        </div>
        {details.shopping_cart_id && (
          <div className={styles.detailsPair}>
            <p>Shopping Cart ID</p>
            <p>{details.shopping_cart_id}</p>
          </div>
        )}
        {details.date_created && (
          <div className={styles.detailsPair}>
            <p>Date & Time</p>
            <p>{moment(details.date_created).format('Do MMM YYYY, h:mm a')}</p>
          </div>
        )}
        {details.stan && (
          <div className={styles.detailsPair}>
            <p>STAN</p>
            <p>{details.stan}</p>
          </div>
        )}
        <div className={styles.detailsPair}>
          <p>Status</p>
          <Tag className={`${styles.tagStatus} ${styles.tagStatusPaid}`}>
            Paid
          </Tag>
        </div>
        {details.cc_type && (
          <div className={styles.detailsPair}>
            <p>Payment Type</p>
            <p>{details.cc_type}</p>
          </div>
        )}
        {details.cc_number && (
          <div className={styles.detailsPair}>
            <p>Credit Card Number</p>
            <p>{details.cc_number}</p>
          </div>
        )}
        {details.order_id && (
          <div className={styles.detailsPair}>
            <p>Order ID</p>
            <p>{details.order_id}</p>
          </div>
        )}

        <div className={styles.detailsPair}>
          <p>Amount</p>
          <p>{details.amount_eur ? `${details.amount_eur} EUR (${details.amount_usd} USD)` : `${details.amount_usd} USD`}</p>
        </div>
      </div>
      <div className={styles.customerInformation}>
        <h4>Customer Information</h4>
        {details.customer_first_name && (
          <div className={styles.detailsPair}>
            <p>First Name</p>
            <p>{details.customer_first_name}</p>
          </div>
        )}
        {details.customer_last_name && (
          <div className={styles.detailsPair}>
            <p>Last Name</p>
            <p>{details.customer_last_name}</p>
          </div>
        )}
        {details.customer_address && (
          <div className={styles.detailsPair}>
            <p>Address</p>
            <p>{details.customer_address}</p>
          </div>
        )}
        {details.customer_country && (
          <div className={styles.detailsPair}>
            <p>Country</p>
            <p>{details.customer_country}</p>
          </div>
        )}
        {details.customer_postal && (
          <div className={styles.detailsPair}>
            <p>ZIP Code</p>
            <p>{details.customer_postal}</p>
          </div>
        )}
        {details.customer_email && (
          <div className={styles.detailsPair}>
            <p>E-mail Address</p>
            <p>{details.customer_email}</p>
          </div>
        )}
      </div>
    </>
  );
};
