import React from 'react';

import './Tracker.css';

export const Tracker = ({ name, trackerName, imgPath, updateSelectedTracker, selectedTracker }) => {
  const displayTracker = () => {
    if (imgPath !== '') {
      return <img className="trackerLogo" src={imgPath} alt="Voluum logo" />;
    } else {
      return <div className="trackerLogo"> {name}</div>;
    }
  };
  return (
    <div
      className={selectedTracker === trackerName ? 'trackerLogoContainerSelected' : 'trackerLogoContainer'}
      onClick={() => updateSelectedTracker(trackerName)}
    >
      {displayTracker()}
    </div>
  );
};
