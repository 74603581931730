import React from 'react';
import { Popover, Button, Checkbox, Row, Col } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { useState } from 'react';
import styles from './Filters.module.css';

export const ColumnsGroup = ({ onChange, checkedColumns, columnsData, showUpdateButton, onUpdate }) => {
  const renderColumns = () => {
    const renderRows = (perRow) => {
      let rows = [];
      for (let i = 0; i < columnsData.length; i += perRow) {
        const rowCols = [];
        for (let j = i; j < i + perRow; j++) {
          const column = columnsData[j];
          if (column) {
            const col = (
              <Col span={8} key={column.value}>
                <Checkbox
                  value={column.value}
                  checked={!!checkedColumns[column.value]}
                  onChange={({ target }) => onChange({ ...checkedColumns, [target.value]: target.checked })}
                  disabled={column.disabled}
                >
                  {column.text}
                </Checkbox>
              </Col>
            );
            rowCols.push(col);
          }
        }
        const row = (
          <Row style={{ marginBottom: '10px' }} key={i}>
            {rowCols}
          </Row>
        );
        rows.push(row);
      }
      return rows;
    };

    return (
      <div className={styles.columnGroupContainer}>
        {renderRows(3)}
        <br />
        {!showUpdateButton ? null : (
          <Row>
            <Button
              style={{ float: 'right' }}
              onClick={() => {
                setColumnsVisibility(false);
                onUpdate();
              }}
            >
              Update
            </Button>
          </Row>
        )}
      </div>
    );
  };
  const [columnsVisibility, setColumnsVisibility] = useState(false);
  return (
    <Popover
      placement="bottomRight"
      trigger="click"
      visible={columnsVisibility}
      onVisibleChange={() => setColumnsVisibility((prevState) => !prevState)}
      content={renderColumns()}
    >
      <Button className={styles.topActionButton}>
        <MenuOutlined rotate={90} />
        Columns
      </Button>
    </Popover>
  );
};
