import React from 'react';
import { Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import styles from './Show.module.css';

export const BudgetCol = ({
  budget,
  setBudgetInfo,
  setCampaignId,
  id,
  setBudgetModalVisible
}) => {

  const openBudgetModal = () => {
    setBudgetModalVisible(true)
    setCampaignId(id)
    setBudgetInfo(budget)
  }

  return (
    <div className={styles.budgetColContainer}>
      <span>{budget}</span>
      <Button onClick={openBudgetModal} icon={<EditOutlined />}></Button>
    </div>
  );
};
