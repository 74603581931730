import React from 'react';
import styles from '../Dashboard.module.css';

export const CustomLoader = () => {
  return (
    <div className={styles.customLoaderContainer}>
      <img src="/images/button_rocket.svg" alt="" />
    </div>
  );
};
