import { Modal, Table } from 'antd';
import React from 'react';
import { MACROS } from '../../../../../utils';

export const MacroInfoModal = ({ visible, setVisible }) => {
  const columns = [
    {
      title: 'Macro',
      dataIndex: 'value',
      key: 'value'
    },
    {
      title: 'Description',
      dataIndex: 'desc',
      key: 'desc'
    }
  ];

  return (
    <Modal style={{ top: 40 }} title="Available Macros" visible={visible} footer={false} onCancel={() => setVisible(false)} width={700}>
      <Table rowKey={(item) => item.text} dataSource={MACROS} columns={columns} pagination={false} bordered scroll={{ y: '70vh' }} />
    </Modal>
  );
};
