import { callApi } from './api';

class LockPageService {
  async resetLockedMail(data) {
    return await callApi({
      url: '/api/advertisers/reset_locked_account/send_mail/',
      method: 'POST',
      data
    });
  }
}

export const lockPageService = new LockPageService();
