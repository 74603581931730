import 'react-select/dist/react-select.css';
import 'react-virtualized-select/styles.css';
import React from 'react';
import Select from 'react-virtualized-select';

export const VirtualizedSelect = ({ options, onChange, multi, selectValue, placeholder }) => {
  return (
    <Select
      options={options}
      style={{ width: '100%' }}
      closeOnSelect={!multi}
      onSelectResetsInput={false}
      isClearable={true}
      placeholder={placeholder}
      multi={multi}
      onChange={(selection) => {
        const value = multi ? selection.map((el) => el.value) : selection ? selection.value : selection;
        onChange(value);
      }}
      value={selectValue}
    />
  );
};
