import { LoadingOutlined } from '@ant-design/icons';
import React from 'react';
import styles from './HeadInfo.module.css';

export const HeadCardInfo = ({ loading, title, value, valueDesc, color }) => {
  return (
    <div className={styles.infoCard}>
      <p>{title}</p>
      <div className={styles.cardValues}>
        <p style={{ color }}>{loading ? <LoadingOutlined spin /> : value}</p>
        <p>{valueDesc}</p>
      </div>
    </div>
  );
};
