import { callApi } from "./api";

class ResetPassword {
  async sendEmailVerification(data) {
    return await callApi({
      url: "api/advertisers/reset_password/send_mail/",
      method: "POST",
      data,
    });
  }
  async resetPassword(data) {
    return await callApi({
      url: "api/advertisers/reset_password/",
      method: "POST",
      data,
    });
  }
}

export const resetPasswordService = new ResetPassword();
