import React, { useContext, useState, useMemo } from 'react';
import { Modal, Form, Input, Button } from 'antd';
import { agreementSigningService } from '../../../services/agreementSigning';
import { openSuccessNotification, openWarningNotification } from '../../../utils/notifications';
import styles from './AgreementModal.module.css';
import UserContext from '../../../contexts/userContext';

export const AgreementModal = ({ agreementModalVisible, setAgreementModalVisible }) => {

    const { userInfo, setUserInfo } = useContext(UserContext);

    const [loading, setLoading] = useState(false);

    const [form] = Form.useForm();

    const handleSubmit = async (values) => {
        form.validateFields();
        if (
            !values.email.match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )
        ) {
            openWarningNotification({ message: 'Email is not valid!' });
            return;
        }
        setLoading(true);
        try {
            const res = await agreementSigningService.forwardAgreement(values);
            if (res.success) {
                openSuccessNotification({ message: res.message });
                setAgreementModalVisible(false);
                setUserInfo(prevUser => ({ ...prevUser, agreement: { status: 'in_progress' } }));
            } else {
                openWarningNotification({ message: res.message });
            }
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    };

    const closeAgreementModal = () => {
        setAgreementModalVisible(false);
    };

    const initialValues = useMemo(() => {
        return {
            first_name: userInfo.authorized_first_name,
            last_name: userInfo.authorized_last_name,
            email: userInfo.authorized_email
        };
    }, [userInfo]);

    return (
        <Modal
            title={'Forwarding agreement to your e-mail'}
            visible={agreementModalVisible}
            onCancel={closeAgreementModal}
            footer={false}
            centered={true}
            width={460}
            wrapClassName={styles.agreementModalWrapper}
        >
            <p>Before we forward the agreement, please insert details of the authorized representative. If you’re the authorized representative, enter the information you’re using on the platform.</p>
            <Form
                layout="vertical"
                form={form}
                onFinish={handleSubmit}
                initialValues={initialValues}
            >
                <Form.Item
                    label="First name of authorized representative:"
                    name="first_name"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter name of authorized representative!',
                        },
                    ]}
                >
                    <Input placeholder="Enter name of authorized representative here" />
                </Form.Item>
                <Form.Item
                    label="Last name of authorized representative:"
                    name="last_name"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter name of authorized representative!',
                        },
                    ]}
                >
                    <Input placeholder="Enter name of authorized representative here" />
                </Form.Item>
                <Form.Item
                    label="E-mail address of authorized representative:"
                    name="email"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter e-mail of authorized representative!',
                        },
                    ]}
                >
                    <Input placeholder="Enter e-mail of authorized representative here" />
                </Form.Item>
                <Form.Item className={styles.actionButtons}>
                    <Button onClick={closeAgreementModal}>
                        Cancel
                    </Button>
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                    >
                        Forward agreement
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};