import { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { getCancelToken, isCancelled } from '../..//services/api';
import { showApiErrors } from '../showApiErrors';

function useRequest(fn) {
  const sourceRef = useRef(null);

  const callFn = useRef(fn);
  useEffect(() => {
    callFn.current = fn;
  }, [fn]);

  const request = useCallback((...args) => {
    const cancelSource = getCancelToken();

    const ready = () => {
      sourceRef.current = cancelSource;
      return callFn.current(cancelSource.token, ...args);
    };

    return {
      ready,
      cancel: cancelSource.cancel
    };
  }, []);

  const clear = useCallback((message) => {
    if (sourceRef.current) {
      sourceRef.current.cancel(message);
    }
  }, []);

  useEffect(() => {
    return () => {
      clear();
    };
  }, [clear]);

  return [
    {
      clear
    },
    request
  ];
}

export function useFetch(fn, initialData = { results: [], count: 0 }) {
  const [data, setData] = useState(initialData);
  const [fetchingCount, setFetchingCount] = useState(0); // int instead of boolean because when canceling request, new request is started before loading indicator of previous request is cleared

  const [{ clear }, createRequest] = useRequest(fn);

  const request = useCallback(
    (...args) => {
      clear(); // cancel previous request
      const { ready, cancel } = createRequest(...args);

      (async function flow() {
        try {
          setFetchingCount((count) => count + 1);
          const data = await ready();
          setData(data);
        } catch (error) {
          if (!isCancelled(error)) {
            showApiErrors(error);
          }
        } finally {
          setFetchingCount((count) => count - 1);
        }
      })();

      return cancel;
    },
    [createRequest, clear]
  );

  return useMemo(() => {
    const cancel = (message) => {
      clear(message);
    };

    const result = [{ data, isFetching: !!fetchingCount, cancel }, request];
    return result;
  }, [data, fetchingCount, request, clear]);
}
