import React, { useEffect, useState } from 'react';
import { PlusOutlined, MinusOutlined, WarningFilled, CheckOutlined } from '@ant-design/icons';
import { InputNumber, Button, Tooltip } from 'antd';
import styles from './NestedTable.module.css';
// import Form from 'antd/lib/form/Form';
// import FormItem from 'antd/lib/form/FormItem';
import { openErrorNotification } from '../../../utils/notifications';
import { reportsService } from '../../../services/reports';

export const PricingColumn = ({ item, showPricing, campaign_id }) => {
  const [changeActive, setChangeActive] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [priceDisp, setPriceDisp] = useState();
  // const [visible, setVisible] = useState(false);

  useEffect(() => {
    setPriceDisp(parseFloat(item.price).toFixed(3));
  }, [item.price]);

  const handlePriceUpdate = async (value) => {
    setDisabled(true);
    let newValue = parseFloat(value).toFixed(3);
    try {
      const targeting =
        showPricing === 'countries'
          ? { countries: item.country }
          : showPricing === 'source'
            ? { subid: item.source }
            : showPricing === 'categories'
              ? { categories: item.iab_id }
              : null;
      if (!targeting) {
        return;
      }
      const res = await reportsService.updatePricingValue({
        ...targeting,
        campaign_id,
        value: newValue
      });
      if (res.success) {
        setPriceDisp(newValue);
        // setVisible(false);
      } else {
        openErrorNotification({ message: res.message });
        setPriceDisp(parseFloat(item.price).toFixed(3));
      }
    } catch (e) {
      openErrorNotification('Unable to update pricing!');
    } finally {
      setChangeActive(false);
      setDisabled(false);
    }
  };

  const calculatePercentage = (price, basePrice) => {
    let percentage = Math.floor((price / basePrice) * 100) - 100;
    if (percentage > 0) {
      percentage = `+${percentage}%`;
    } else if (percentage < 0) {
      percentage = `${percentage}%`;
    } else {
      return null;
    }
    return <span className={styles.percentageBox}>{percentage}</span>;
  };

  return (
    <div className={styles.pricingColumn} style={{ opacity: disabled ? 0.5 : 1, pointerEvents: disabled && 'none' }}>
      {item.base_price && (
        <div className={styles.percentageContainer}>
          {calculatePercentage(priceDisp, item.base_price)}
        </div>
      )}
      {item.base_price ? (
        <>
          <div className={styles.priceUpdateContainer}>
            <Tooltip title="-10%">
              <Button size='medium' onClick={() => handlePriceUpdate(priceDisp * 0.9)}>
                <MinusOutlined />
              </Button>
            </Tooltip>
            {item.base_price && (
              <div className={styles.mainPriceBox}>
                <Tooltip title="Make sure you press enter or click checkmark to save CPC">
                  <InputNumber
                    value={priceDisp}
                    formatter={value => `$${value}`}
                    onChange={(value) => {
                      setChangeActive(true);
                      setPriceDisp(value);
                    }}
                    onPressEnter={(e) => handlePriceUpdate(e.target.value.substring(1) * 1)}
                  />
                </Tooltip>
              </div>
            )}
            <Tooltip title="+10%">
              <Button size='medium' onClick={() => handlePriceUpdate(priceDisp * 1.1)}>
                <PlusOutlined />
              </Button>
            </Tooltip>
            {/* <Popover
              title={`Default CPC($${item.base_price})`}
              visible={visible}
              onVisibleChange={setVisible}
              content={
                <Form onFinish={(values) => handlePriceUpdate(parseFloat(values.price))}>
                  <div className={styles.priceEditPop}>
                    <FormItem name="price" style={{ marginBottom: 0 }}>
                      <Input style={{ width: '120px' }} min="0.001" max="1000" addonBefore="$" type="number" step="0.001" />
                    </FormItem>
                    <UndoOutlined
                      className={styles.pricingUndoBtn}
                      onClick={() => {
                        handlePriceUpdate(parseFloat(item.base_price));
                      }}
                    />
                  </div>
                  <div className={styles.priceEditBtns}>
                    <Button onClick={() => setVisible(false)}>Cancel</Button>
                    <Button htmlType="submit" type="primary" style={{ marginLeft: '1rem' }}>
                      Save
                    </Button>
                  </div>
                </Form>
              }
              trigger="click"
            >
              <Button className={styles.editPriceBtn} size="small" type="text" icon={<EditOutlined />}></Button>
            </Popover> */}
          </div>
          <Tooltip title="Save CPC">
            <Button disabled={!changeActive} className={changeActive ? styles.confirmButtonGreen : styles.confirmButtonDisabled} size="small" icon={<CheckOutlined />} onClick={() => handlePriceUpdate(priceDisp)} />
          </Tooltip>
        </>
      ) : (
        <Tooltip title="In order to change your CPC, please set your CPC value within Campaign - General Configuration">
          <WarningFilled style={{ fontSize: '1.2rem', color: '#f7b217' }} />
        </Tooltip>
      )}
    </div>
  );
};
