import React from 'react';
import moment from 'moment';
import { Tag } from 'antd';
import styles from '../Payment.module.css';

export const PaymentFailed = ({ details }) => {
  return (
    <>
      <h3 className={styles.paymentTitle}>Your {details.payment_type} Payment Has Been Unsuccessful!</h3>
      <h4 className={styles.tyMessage}>Your transaction has failed to go through.</h4>
      <div className={styles.transactionDetails}>
        <h4>Transaction Details</h4>
        <div className={styles.detailsPair}>
          <p>Company Name</p>
          <p>Coinis LTD</p>
        </div>
        <div className={styles.detailsPair}>
          <p>Shop ID</p>
          <p>COINISME</p>
        </div>
        <div className={styles.detailsPair}>
          <p>Status</p>
          <Tag className={`${styles.tagStatus} ${styles.tagStatusFailed}`}>
            Failed
          </Tag>
        </div>
        <div className={styles.detailsPair}>
          <p>Shopping Cart ID</p>
          <p>{details.shopping_cart_id}</p>
        </div>
        <div className={styles.detailsPair}>
          <p>Date & Time</p>
          <p>{moment(details.date_created).format('Do MMM YYYY, h:mm a')}</p>
        </div>
        <div className={styles.detailsPair}>
          <p>Amount</p>
          <p>{details.amount_eur ? `${details.amount_eur} EUR (${details.amount_usd} USD)` : `${details.amount_usd} USD`}</p>
        </div>
      </div>
      <div className={styles.customerInformation}>
        <h4>Customer Information</h4>
        <div className={styles.detailsPair}>
          <p>First Name</p>
          <p>{details.customer_first_name}</p>
        </div>
        <div className={styles.detailsPair}>
          <p>Last Name</p>
          <p>{details.customer_last_name}</p>
        </div>
        <div className={styles.detailsPair}>
          <p>Address</p>
          <p>{details.customer_address}</p>
        </div>
        <div className={styles.detailsPair}>
          <p>Country</p>
          <p>{details.customer_country}</p>
        </div>
        <div className={styles.detailsPair}>
          <p>ZIP Code</p>
          <p>{details.customer_postal}</p>
        </div>
        <div className={styles.detailsPair}>
          <p>E-mail Address</p>
          <p>{details.customer_email}</p>
        </div>
      </div>
    </>
  );
};
