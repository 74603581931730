import React from 'react';
import { PlusOutlined, DeleteOutlined, StopOutlined } from '@ant-design/icons';
import styles from '../CreativeBuilder.module.css';
import { openWarningNotification } from '../../../../../../../../../utils/notifications';

export const ImageSingleUploader = ({ singleImage, setSingleImage, setImageModalVisible }) => {
  const handleDeleteImage = () => setSingleImage([]);

  return (
    <div>
      <p className={styles.builderLabel}>
        <span className={styles.requiredMark}>* </span>Image{' '}
        <span>
          Minimum size <span>600x400</span> pixels
        </span>
      </p>
      <div className={styles.previeImageBox}>
        {singleImage.map((image, key) => {
          return (
            <div key={key} className={styles.singleImagePreview} onClick={() => handleDeleteImage(image)}>
              <img src={image} alt="creatives" />
              <div className={styles.singleImageDelete}>
                <DeleteOutlined className={styles.deleteIcon} />
              </div>
            </div>
          );
        })}
        <div
          className={styles.addNewImageBtn}
          onClick={() => {
            if (singleImage.length < 1) {
              setImageModalVisible('single');
            } else {
              openWarningNotification({ message: "You can't add more than 1 image!, please delete existing one!" });
            }
          }}
        >
          {singleImage.length < 1 ? <PlusOutlined style={{ fontSize: '2rem' }} /> : <StopOutlined style={{ fontSize: '2rem' }} />}
        </div>
      </div>
    </div>
  );
};
